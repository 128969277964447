import Vue from 'vue/dist/vue.esm.js'
import VModal from 'vue-js-modal'
import SceneTable from '../scene_table.vue'
import Notifications from 'vue-notification'

document.addEventListener('turbolinks:load', () => {
  if(!document.getElementById('scene-table')){
    return
  }

  Vue.use(VModal)
  Vue.use(Notifications)

  const app = new Vue({
    el: '#scene-table',
    components: {
      SceneTable
    }
  });
});
