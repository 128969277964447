<template>
  <modal name="music-select-modal" width="85%" height="auto" scrollable>
    <div class="modal-header">
      <h2 class="modal-title">曲検索</h2>
    </div>
    <div class="modal-body">
      <form @submit.prevent class="row row-cols-lg-auto g-3 align-items-center pb-3">
        <div class="col-12">
          <input class="form-control" v-model="searchParams.music_name" placeholder="曲名">
        </div>
        <div class="col-12">
          <input class="form-control" v-model="searchParams.artist_name" placeholder="アーティスト名">
        </div>
        <div class="col-12">
          <input type="submit" class="btn btn-primary" @click="search" value="検索">
        </div>
      </form>
      <div class="table-responsive">
        <table class="table table-bordered table-striped">
          <thead>
            <tr>
              <th nowrap style="width:100px;">曲名</th>
              <th nowrap>アーティスト名</th>
              <th nowrap>視聴</th>
              <th nowrap>選択</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="music in musics">
              <td>
                {{ music.name}}
                <template v-if="music.nameFurigana">
                  <br>
                  <span class="furigana"> {{ music.nameFurigana }} </span>
                </template>
                <template v-if="music.albumName">
                  <br>
                  <span class="furigana"> アルバム: {{ music.albumName }} </span>
                </template>
              </td>
              <td>
                {{ music.artistName }}
                <template v-if="music.artistNameFurigana">
                  <br>
                  <span class="furigana"> {{ music.artistNameFurigana }} </span>
                </template>
              </td>
              <td>
                <audio controls :src="music.audioFilePath" preload="metadata" :ref="'music'+music.id" @play="pauseOtherMusics(music)"></audio>
              </td>
              <td>
                <a @click="select(music.id)">選択</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="alert alert-warning" role="alert" v-if="candidateCount==0">
        該当なし
      </div>
      <nav v-if="totalPages > 0" class="mx-auto" style="width:400px;">
        <paginate
          :page-count="totalPages"
          :page-range="3"
          :margin-pages="2"
          :click-handler="goPage"
          :prev-text="'＜'"
          :next-text="'＞'"
          :container-class="'pagination'"
          :page-class="'page-item'"
          :page-link-class="'page-link'"
          :next-link-class="'page-link'"
          :prev-link-class="'page-link'"
        >
        </paginate>
      </nav>
    </div>
  </modal>
</template>
<script>
  import axios from 'axios'
  import Paginate from 'vuejs-paginate'

  export default {
    data() {
      return {
        searchParams: {
          music_name: null,
          artist_name: null
        },
        musics: [],
        index: -1,
        totalPages: 0,
        searchCount: 0,
      }
    },
    methods: {
      show : function(index) {
        this.$modal.show('music-select-modal');
        this.index = index
      },
      hide : function () {
        this.$modal.hide('music-select-modal');
      },
      search: function(page = 1) {
        console.log("searching...")
        this.music = []
        const self = this
        this.searchParams["page"] = page
        axios.get('/musics.json', { params: this.searchParams })
          .then(function(response) {
            self.searchCount ++
            self.musics = response.data.musics
            self.totalPages = response.data.pages.total
          })
      },
      select: function(musicId) {
        this.$emit("select", musicId, this.index)
        this.hide();
      },
      goPage: function(page) {
        this.search(page)
      },
      pauseOtherMusics(targetMusic) {
        let refName = null;
        this.musics.forEach(music => {
            if(music.id != targetMusic.id) {
              refName = 'music' + music.id;
              this.$refs[refName][0].pause();
            }
          }
        )
      }
    },
    components: {
      Paginate
    },
    computed: {
      candidateCount() {
        if(this.searchCount == 0) {
          return -1
        }
        else {
          return this.musics.length
        }
      }
    }
  }
</script>
<style scoped>
  th {
    text-align: center ! important;
  }
  td {
    text-align: center ! important;
  }
</style>
