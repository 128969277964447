<template>
  <div>
    <notifications group="save" />
    <input type="button" class="btn btn-primary mb-1" value="行追加" @click="addScene" :disabled="!event.editable">
    <form ref="eventForm" class="table-responsive">
      <table class="table table-bordered table-striped" id="scene-table">
        <thead>
          <tr>
            <th class="rarrow"></th>
            <th class="narrow">順番</th>
            <th nowrap style="width:300px;">シーン</th>
            <th nowrap class="narrow">曲検索</th>
            <th nowrap class="narrow">おまかせ</th>
            <th nowrap>曲名</th>
            <th nowrap >アーティスト名</th>
            <th>視聴</th>
            <th>備考</th>
            <th class="narrow">削除</th>
          </tr>
        </thead>
        <draggable tag="tbody" :list="scenes" @change="dragChange" :options="{handle: '.handle', delay:100}">
          <tr v-for="(scene, index) in scenes" :key="scene.id">
            <template v-if="!scene.isDelete">
              <td class="handle">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-grip-vertical" viewBox="0 0 16 16">
                  <path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                </svg>
              </td>
              <td>
                <div class="form-group">
                  <input v-model="scene.displayOrder" class="form-control" type="number" style="width:60px;" min="1">
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input v-model="scene.name" class="form-control" required :readonly="!event.editable" style="min-width:200px;">
                </div>
              </td>
              <td>
                <a @click="openModal(index, $event)">曲選択</a>
              </td>
              <td>
                <input type="checkbox" v-model="scene.isCommitted">
              </td>
              <td nowrap>
                {{ scene.musicName }}
                <template v-if="scene.musicNameFurigana">
                  <br>
                  <span class="furigana">{{ scene.musicNameFurigana }}</span>
                </template>
                <template v-if="scene.musicAlbumName">
                  <br>
                  <span class="furigana"> アルバム: {{ scene.musicAlbumName }} </span>
                </template>
              </td>
              <td nowrap>
                {{ scene.artistName }}
                <template v-if="scene.artistNameFurigana">
                  <br>
                  <span class="furigana">{{ scene.artistNameFurigana }}</span>
                </template>
              </td>
              <td>
                <audio controls :src="scene.audioFilePath" preload="metadata" :ref="'scene'+scene.id" @play="pauseOtherMusics(scene.id)" controlslist="nodownload"></audio>
              </td>
              <td>
                <textarea :rows="textareaRows(scene.remarks)" v-model="scene.remarks" class="form-control" style="width:200px;"></textarea>
              </td>
              <td>
                <button type="button" class="btn-close" aria-label="Close" @click="scene.isDelete=true" :disabled="!event.editable"></button>
              </td>
            </template>
          </tr>
        </draggable>
      </table>
    </form>

    <music-select-modal ref="musicSelectModal" @select="select"></music-select-modal>
    <chat-time-bgm-selector :initial-type="event.chatTimeBgmType" :initial-memo="event.chatTimeBgmMemo" @input="setChatTimeBgm" class="mb-3"></chat-time-bgm-selector>
    <h4>備考</h4>
    <textarea v-model="event.remarks" style="width: 100%;" :rows="textareaRows(event.remarks, 5)" placeholder="備考" :readonly="!event.editable"></textarea>
    <div class="d-grid gap-2">
      <button class="btn btn-block btn-success" @click="save" :disabled="!event.editable">保存</button>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
  import MusicSelectModal from './music_select_modal.vue'
  import ChatTimeBgmSelector from './chat_time_bgm_selector.vue'
  import draggable from 'vuedraggable'
  axios.defaults.headers.common = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  };

  export default {
    props: {
      eventCode: {
        type: String
      }
    },
    data: function(){
      return {
        scenes: [],
        event: {}
      }
    },
    mounted: function(){
      this.loadData()
    },
    methods: {
      loadData: function() {
        const self = this
        axios.get('/scenes.json?code='+this.eventCode)
          .then(function(response) {
            self.scenes = response.data
            self.scenes.forEach( (scene, index) => {
              self.$set(scene, 'isDelete', false)
              scene.displayOrder = index + 1
            })
          })
        axios.get('/events/by_code/'+this.eventCode+'.json')
          .then(function(response) {
            for( let key in response.data.event ){
              self.$set(self.event, key, response.data.event[key])
            }
          })
      },
      addScene: function() {
        const self = this
        axios.get('/scenes/new.json')
          .then(function(response) {
            const newData = response.data
            newData.isDelete = false
            self.$set(self.scenes, self.scenes.length, newData)
          })
      },
      openModal: function(index, ev) {
        if(ev) {
          ev.preventDefault()
        }
        this.$refs.musicSelectModal.show(index)
      },
      select: function(musicId, index) {
        const self = this
        axios.get('/musics/'+musicId+'.json')
          .then(function(response) {
            let scene = self.scenes[index]
            scene.musicId = musicId
            scene.musicName = response.data.name
            scene.artistName = response.data.artistName
            scene.audioFilePath = response.data.audioFilePath
          })
      },
      save: async function() {
        if(!this.$refs.eventForm.reportValidity()) {
          return
        }

        let isConflict = false
        await axios.put('/events/'+this.event.id, { event: this.event, scenes: this.aliveScenes } )
          .then(function(response){

          })
          .catch(err => {
            isConflict = err.response.status == 409
          })

        if(isConflict) {
          this.$notify({
            group: 'save',
            title: '他のユーザーが先にデータを更新したようです。画面をリロードしてデータを取得し直してください。',
            type: 'warn'
          })
          return
        }

        await axios.delete('/scenes/bulk_destroy', { data: { ids: this.deleteScenes.map(scene => scene.id) } })
        this.$notify({
          group: 'save',
          title: '保存しました。',
          type: 'success'
        })

        this.loadData()
      },
      textareaRows: function(text, defaultRows=1) {
        if(!text) {
          return defaultRows
        }
        return  text.split("\n").length+1
      },
      setChatTimeBgm: function(type, memo) {
        this.event.chatTimeBgmType = type
        this.event.chatTimeBgmMemo = memo
      },
      pauseOtherMusics(scene_id) {
        let refName = null;
        this.scenes.forEach(scene => {
            if(scene.id != scene_id) {
              refName = 'scene' + scene.id;
              this.$refs[refName][0].pause();
            }
          }
        )
      },
      dragChange(change) {
        this.scenes.forEach( (scene, index) => {
          scene.displayOrder = index+1
        })
      }
    },
    computed: {
      aliveScenes() {
        return this.scenes.filter(scene => !scene.isDelete)
      },
      deleteScenes() {
        return this.scenes.filter(scene => scene.isDelete)
      }
    },
    components: {
      MusicSelectModal,
      ChatTimeBgmSelector,
      draggable
    }
  }
</script>
<style lang="scss" scoped>
  th {
    text-align: center ! important;

  }
  th.narrow {
    width: 80px;
  }
  td {
    text-align: center ! important;
  }
  #scene-table {
    a {
      color: blue;
      text-decoration: underline;
    }
    a:hover {
      color: #0099FF;
    }
  }
</style>
