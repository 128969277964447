<template>
  <div>
    <h4>歓談BGM</h4>
    <span>下記からお選びください</span>
    <ul>
      <li v-for="(label, key) in allBgmTypes" style="display:inline-block;margin-left:15px;">
        <input name="bgmType" type="radio" :value="key" @change="input" :id="'bgm-type-'+key" v-model="bgmType">
        <label :for="'bgm-type-'+key" style="font-weight:normal;">{{ label }}</label>
      </li>
    </ul>
    <div class="row" style="pl-3">
      <div class="col-12">
        <input placeholder="その他をお選びの方はこちらにご入力ください。" @input="input" v-model="memo" class="form-control" v-if="isOthers">
        <input placeholder="その他をお選びの方はこちらにご入力ください。" @input="input" class="form-control" :disabled="true" v-else>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'

  export default {
    props: {
      initialType: {
        type: String,
        default: null
      },
      initialMemo: {
        type: String,
        default: null
      }
    },
    data: function(){
      return {
        allBgmTypes: {},
        bgmType: '',
        memo: ''
      }
    },
    mounted: function(){
      this.loadData()
    },
    methods: {
      loadData: function() {
        const self = this;
        axios.get('/events/chat_time_bgm_types.json')
          .then(function(response){
            self.allBgmTypes = response.data
          })
      },
      input: function() {
        this.$emit('input', this.bgmType, this.bgmMemo)
      }
    },
    watch: {
      initialType: function(val) {
        if(!this.bgmType) {
          this.bgmType = val
        }
      },
      initialMemo: function(val) {
        if(!this.memo) {
          this.memo = val
        }
      }
    },
    computed: {
      bgmMemo: function() {
        return this.isOthers ? this.memo : null
      },
      isOthers: function() {
        return this.bgmType == 'others'
      }
    }
  }
</script>
<style lang="scss" scoped>
  ul {
    list-style: none;
    padding-left: 0px ! important;
  }
</style>
